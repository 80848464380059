<template>
  <div class="readme-article">
    <div id="财务"></div>
    <h1 id="员工资料">
      一、员工资料
    </h1>
    <h2 id="员工资料查询">1.员工资料查询</h2>
    <h3 id="员工列表">1.1员工列表</h3>
    <p>查看员工列表，展示员工基本信息，可点击操作按钮查看详情以及考勤。</p>
    <p>
      <img src="@/assets/img/staff/1-1.png">
    </p>
    <h3 id="员工详情">1.2员工详情</h3>
    <p>查看员工详情，管理员可编辑员工的基本资料、详细资料。</p>
    <p>
      <img src="@/assets/img/staff/1-2.png">
    </p>
    <h3 id="员工考勤">1.3员工考勤</h3>
    <p>管理员可添加和查看员工的考勤记录。</p>
    <p>
      <img src="@/assets/img/staff/1-3.png">
    </p>
    <h3 id="呼叫配置">1.4呼叫配置</h3>
    <p>有购买外呼坐席的用户可以在员工详情里的呼叫配置中设置坐席，系统云外呼稳定防封号。</p>
    <p>
      <img src="@/assets/img/staff/1-4.png">
    </p>
    <h2 id="添加新员工">2.添加新员工</h2>
    <p>管理员可添加新员工，需要填写员工Id（员工登录账号）、员工姓名、所属用户组、状态信息。</p>
    <p>
      <img src="@/assets/img/staff/1-5.png">
    </p>
    <h2 id="健康打卡">3.健康打卡</h2>
    <p>查看员工小程序端健康打卡记录，包含当日打卡温度、健康码。</p>
    <p>
      <img src="@/assets/img/staff/1-6.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Staff1-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>